import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

// All the gatsby-plugin-image goodness
import { StaticImage, } from "gatsby-plugin-image"
// GatsbyImage, getImage 
// The bridge for Gatsby Background Image in V3
// import { BgImage } from 'gbimage-bridge'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLaptopCode, faMicrophoneAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

// React Bootstrap
import { Container, Row, Col } from "react-bootstrap"

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Web Design / Development & Podcasting Services for Small Businesses" />
    <div className="masthead blue-gradient">
      <Container>
        <div className="masthead d-md-flex text-md-start text-center  align-items-center">
          <div className="px-1 px-sm-5">
          <h1>We design & build websites that both <strong>clients & Google</strong> will love.</h1>
          <p class="lead">Add in a podcast & a lead generation form and grow your audience.</p>

          <Link to="/contact/" className="btn btn-light mb-4">
            Get Started
          </Link>
          <Link to="/web-design-development/" className="btn btn-outline-light ms-3 mb-4">
            View our Portfolio
          </Link>
          </div>
          <div className="px-1 px-sm-5">
          <StaticImage 
                src="../../static/images/golden-isles-arts-website.png"
                alt="Golden Isles Arts Website"
                placeholder="blurred"
                width="1000"
                className="position-relative"
              />
          
          </div>
        </div>
      </Container>
    </div>
      
    <Container className="text-center mt-2 mt-sm-5">
    <h2>We make the web work for you</h2>
      <Row className="my-3 my-sm-5 mb-sm-4 mb-2 ">
        <Col lg={4} className="">
          <p><FontAwesomeIcon icon={faLaptopCode} size="2x" /><br /><strong>Web Design & Development</strong></p>
          <p>
            Fast, custom-built websites is what we are all about. Speed + usability = a website customers and clients will love. All of our websites are 100% mobile-friendly and we host on ultra-secure servers.
        </p>
        </Col>
        <Col lg={4} className="text-center">
          <p><FontAwesomeIcon icon={faMicrophoneAlt} size="2x" /><br /><strong>Podcasting</strong></p>
          <p>
            You have something to say. Put it into audio form and spread your message through multiple search engines including: Apple, Spotify, Google and Amazon. Plus you can easily drive traffic from your podcast back to your website.
          </p>
        </Col>
        <Col lg={4} className="text-center">
          <p><FontAwesomeIcon icon={faEnvelopeOpenText} size="2x" /><br /><strong>Email Lead Generation</strong></p>
          <p>
            Don't let a potential just hop on and off of your website. Offer a lead-generation form with a deliverable, trading your knowledge for a coveted email to grow your email list.
          </p>
        </Col>
        
      </Row>
      
    </Container>

    <div className="grey-box">
      <Container className="singleCol py-2 py-sm-5">
        <h2 className="text-center py-2">So just how does Google love your sites?</h2>
        <p>
          Our websites are built for what Google is looking for in 2021: speed. Google has built performance review engines just to measure how fast or slow your site is. <em>But why is that important?</em>
        </p>
        <p>
          Google views that the slower the site, the greater the chance users will back out of the site before it loads. Google ranks your site on 4 categories: Performance, Accessibility, Best Practices and SEO.
        </p>
        <p><strong><em>Is your site in the red, orange or are you all green?</em></strong></p>
        <p>
              <StaticImage 
                src="../images/performance-speed.jpg"
                alt="High Rise buildings"
                placeholder="blurred"
                width="750"
              />
          </p>
          <p>
            It's our goal to design and build a website that your clients will love to use and that Google will happily promote. 
          </p>
      </Container>
    </div>




    <div className="masthead blue-angled-graident py-5">
      <Container>
        <div className="masthead d-md-flex text-md-start text-center  align-items-center">
        <div class="w-100">
          <StaticImage 
                src="../../static/images/lace-house-linen.png"
                alt="Lace House Linen Website"
                placeholder="blurred"
                width="1000"
                className="position-relative"
              />
          
          </div>
          <div className="px-1 px-sm-5  flex-shrink-1">
            <p className="lead">Our company was struggling to keep our website current and stylish. Nautilus Designs immediately understood our goal and helped guide us to understand what worked best for the overall web experience for visitors to our site.</p>
            <p className="lead">They were professional, insightful and very quick to transform ideas into real results. <strong>Lace House Linen highly recommends Nautilus Designs</strong> for a complete website building experience. 
            </p>
            <p>– Richard Marzo, Lace House Linen</p>
          </div>
          
        </div>
      </Container>
    </div>




    <div className="blue-angled-graident">
    <Container className="singleCol mt-3 p">
      
      
    </Container>
    </div>
    
    
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    palmTree: file(relativePath: {eq: "palm-tree.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 500
          webpOptions: {quality: 30}
          blurredOptions: {width: 50}
        )
      }
    }
    oldLock: file(relativePath: {eq: "old-rusty-lock.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 500
          webpOptions: {quality: 30}
          blurredOptions: {width: 50}
        )
      }
    }
    coverPhoto: file(relativePath: {eq: "cover-photo.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`
